import { CURRENCY } from "src/data/constants";
import { getProductUrl, sendCategoryClick } from "src/modules/ubercatalog";
import useLang from "src/services/language/useLang";
import { SearchResultProduct } from "../components/SearchResults/SearchResults";
import { SearchResultsWrapperProps } from "../components/SearchResultsWrapper/SearchResultsWrapper";
import { SearchPlace } from "../helpers/analytics";
import { SearchData } from "./useSearch";
import { useMemo } from "react";
import useFavoritesService from "src/services/favorites/useFavoritesService";

interface Params {
  searchData: SearchData;
  searchIsOpened: boolean;
  searchInputIsFilled: boolean;
  searchString: string;
  pageForAnalytics: SearchPlace;

  closeSearch(): void;
  openSearchPage(): void;
}

const useSearchResultsWrapperProps = (params: Params): SearchResultsWrapperProps => {
  const {
    closeSearch,
    openSearchPage,
    pageForAnalytics,
    searchIsOpened,
    searchInputIsFilled,
    searchString,
    searchData,
  } = params;

  const {
    productsResults,
    searchCategories,
    searchIsFetching,
    resultsAreNotFounded,
    searchResultsAvailableCount,
  } = searchData;

  const lang = useLang();
  const {
    getProductIsFavorite,
    toggleFavorite,
  } = useFavoritesService();

  const handleOnCategoryClick = (slug: string) => {
    sendCategoryClick({
      page: pageForAnalytics,
      place: 'search_category',
      category_id: slug,
    });

    closeSearch();
  };

  const searchResultProducts: SearchResultProduct[] = useMemo(() => productsResults.map(p => ({
    imagesSrc: p.img?.s150x150,
    priceFrom: p.price_details.price_from,
    priceTo: p.price_details.price_to,
    title: p.title,
    volume: p.volume,
    itemInStock: p.in_stock,
    unit: p.unit,
    currency: CURRENCY,
    weight: p.weight,
    bundle: p.bundle,
    url: getProductUrl({ lang, ean: p.ean, slug: p.slug }),
    isFavorite: getProductIsFavorite(p.eans),
    onFavoriteClick: () => toggleFavorite({ product: p, place: 'search' }),
  })), [lang, productsResults, getProductIsFavorite, toggleFavorite]);


  const searchResultsWrapperProps: SearchResultsWrapperProps = {
    isVisible: searchIsOpened && searchInputIsFilled,
    resultsAreVisible: (!!productsResults.length || !!searchCategories.length),
    loaderIsVisible: searchIsFetching,
    resultsAreNotFounded,
    searchString,
    searchResultsProps: {
      products: searchResultProducts,
      categories: searchCategories,
      count: searchResultsAvailableCount,
      searchString,
      onViewAllClick: openSearchPage,
      onCategoryClick: handleOnCategoryClick,
      onProductClick: closeSearch,
    },
  };

  return searchResultsWrapperProps;
};

export default useSearchResultsWrapperProps;

import { MouseEvent } from 'react';
import ProductRow, { ProductRowProps } from 'src/components/ProductRow/ProductRow';
import { useRouterWithLoading } from 'src/modules/global-loading';

export interface SearchResultsProductRowProps extends Omit<ProductRowProps, 'onClick'> {
  onClick: () => void;
  url: string;
}

const SearchResultsProductRow = (props: SearchResultsProductRowProps) => {
  const {
    onClick,
    url,
    ...restProps
  } = props;

  const router = useRouterWithLoading();

  const handleClick = (event: MouseEvent) => {
    if (typeof onClick === 'function') {
      onClick();
    }

    if (event.ctrlKey || event.metaKey) {
      window.open(url, '_blank');

      return;
    }

    router.push(url);
  };

  return (
    <ProductRow
      {...restProps}
      onClick={handleClick}
    />
  );
};

export default SearchResultsProductRow;

import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import { desktop, phone_tablet } from '../../../../styles/media';

export const SearchBoxStyled = styled.div<StyledThemeProps>(({ theme }) => `
  width: 100%;
  position: relative;

  &.SearchBox_headerDesktop {
    display: none;
  }

  &.SearchBox_mobile {
    display: block;
  }

  .SearchBox__clearSearch {
    cursor: pointer;
    margin-right: 12px;
  }

  .SearchBox__icons {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 16px;
    z-index: 4;
    height: 40px;
  }

  .SearchBox__icon {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: ${theme.base.color.gray400};
    width: 20px;
    height: 20px;
    text-decoration: none;
    outline: none;
  }

  .SearchBox__back {
    display: none;
    position: absolute;
    left: 16px;
    top: 18px;
    z-index: 5;
    font-size: 18px;
    cursor: pointer;
  }

  &.SearchBox_mobile .SearchBox__back {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SearchBox__clearSearch {
    margin-right: 8px;
    font-size: 11px;
  }

  .SearchBox__icon:hover {
    color: ${theme.base.color.dark};
  }

  .SearchBox__results {
    position: absolute;
    z-index: 4;
    display: flex;
    flex-direction: column;
    background-color: ${theme.base.color.white};
    overflow: auto;
    max-height: 100vh;
    width: 100%;
  }

  &.SearchBox_headerDesktop .SearchBox__results {
    max-height: calc(100vh - 150px);
  }

  &.SearchBox_mainPage .SearchBox__results {
    max-height: calc(100vh - 195px);
  }

  @media ${desktop} {
    &.SearchBox_headerDesktop {
      display: block;
    }

    .SearchBox__results {
      width: 100%;
      min-width: 540px;
      margin-top: 20px;
    }

    &.SearchBox_mainPage .SearchBox__results {
      margin-bottom: 20px;
    }
  }

  @media ${phone_tablet} {
    .SearchBox__clearSearch {
      margin-right: 8px;
    }
    .SearchBox__results {
      position: absolute;
      top: 56px;
      left: 0;
      bottom: 0;
      right: 0;
      max-height: 100%;
    }

    &.SearchBox_mainPage .SearchBox__results {
      max-height: calc(100vh - 170px);
    }
  }

  @media ${phone_tablet} {
    &.SearchBox_mobile {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }

    .SearchBox__icons_mobileLargeHeight {
      height: 56px;
    }
  }
`);


import styled from '@emotion/styled';
import { phone } from 'src/styles/media';
import typography from 'src/styles/typography';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const SearchResultsStyled = styled.div<StyledThemeProps>(({ theme }) => `
  .SearchResults__productsListTitle {
    ${typography.h6}
    display: block;
    margin-top: 16px;
    margin-left: 20px;
  }

  .SearchResults__viewAll {
    ${typography.h6}
    color: ${theme.button.ghost.color};
    display: flex;
    height: 52px;
    width: 100%;
    border: none;
    outline: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: ${theme.base.color.white};
    border-top: 1px solid ${theme.base.color.gray100};
    border-bottom: 1px solid ${theme.base.color.gray100};
    position: sticky;
    bottom: 0;
  }

  .SearchResults__viewAll:hover {
    color: ${theme.button.ghost.colorHover};
  }

  .SearchResults__categoriesListItem {
    padding: 15px 10px;
    flex-shrink: 0;
  }

  .SearchResults__categoryLink {
    ${typography.text}
    text-decoration: none;
    cursor: pointer;
    transition: none;
  }

  .SearchResults__categoriesListItem:first-of-type {
    padding-left: 20px;
  }

  .SearchResults__categoriesListItem:last-of-type {
    padding-right: 20px;
  }

  .SearchResults__categoryLink:hover {
    color: ${theme.base.color.primary};
  }

  .SearchResults__separator {
    width: 100%;
    height: 4px;
    background: ${theme.base.color.gray100};
  }

  @media ${phone} {
    .SearchResults__productsListTitle {
      margin-left: 10px;
    }
    .SearchResults__categoriesListItem:first-of-type {
      padding-left: 10px;
    }

    .SearchResults__categoriesListItem:last-of-type {
      padding-right: 10px;
    }
  }
`);

